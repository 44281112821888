import React, { Suspense } from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';

import ScrollToTop from './components/ScrollToTop';
import './App.css';

const About = React.lazy(() => import('./screens/About'));
const Home = React.lazy(() => import('./screens/Home'));
const Privacy = React.lazy(() => import('./screens/Privacy'));
const ThankYou = React.lazy(() => import('./screens/ThankYou'));
console.log('trigger deploy');
function App() {
  return (
    <Suspense fallback={<></>}>
      <Router>
        <ScrollToTop />
        <Switch>
          <Route exact path="/" render={() => <Home />} />
          <Route exact path="/about" render={() => <About />} />
          <Route exact path="/privacy" render={() => <Privacy />} />
          <Route exact path="/thank-you" render={() => <ThankYou />} />
        </Switch>
      </Router>
    </Suspense>
  );
}

export default App;
